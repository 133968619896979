import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    FooterLinkEx,
    SocialMedia,
    SocialMediaWrap,
    RightsWrapper,
    WebsiteRights,
    SocialIcons,
    SocialIconLink,
    } from './FooterElements'

const Footer = () => {

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>About us</FooterLinkTitle>
                                <FooterLink to='/contact'>Contact Us</FooterLink>
                                {/* <FooterLink to='/signin'>More Info</FooterLink> */}
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Services</FooterLinkTitle>
                                <FooterLink to='/#FAQ'>FAQ</FooterLink>
                                <FooterLink to='/uploadinfo'>Submission Guidelines</FooterLink>
                                {/* <FooterLink to='/tutorials'>Video Tutorials</FooterLink> */}
                                {/* <FooterLink to='/contract#top'>Contracts</FooterLink> */}
                        </FooterLinkItems>
                    {/* </FooterLinksWrapper> */}
                    {/* <FooterLinksWrapper> */}
                        {/* <FooterLinkItems>
                            <FooterLinkTitle>Contributor</FooterLinkTitle>
                                <FooterLink to='/signin'>Contributor Resources</FooterLink>
                                <FooterLink to='/signin'>Submission Guidelines</FooterLink>
                        </FooterLinkItems> */}
                        <FooterLinkItems>
                            <FooterLinkTitle>Collections</FooterLinkTitle>
                                <FooterLinkEx href='https://www.gettyimages.ca/search/2/image?artist=ascent&autocorrect=none&excludenudity=false&family=creative&license=rm&sort=best' target='_blank' aria-label='Getty Images'>Getty Images</FooterLinkEx>
                                <FooterLinkEx href='https://www.gettyimages.ca/search/2/film?artist=ascent&autocorrect=none&family=creative&license=rr&sort=newest' target='_blank' aria-label='Getty Footage'>Getty Footage</FooterLinkEx>
                                <FooterLinkEx href='https://www.istockphoto.com/portfolio/ascentxmedia?assettype=image&mediatype=photography' target='_blank' aria-label='iStock Images'>iStock Images</FooterLinkEx>
                                <FooterLinkEx href='https://www.istockphoto.com/portfolio/ascentxmedia?assettype=film' target='_blank' aria-label='iStock Footage'>iStock Footage</FooterLinkEx>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    {/* <CreditContainer>
                        <PhotoCredit>Photos by: Reuben Krabbe, PKS Media, Milo Zanecchia, BFC</PhotoCredit>
                    </CreditContainer> */}
                    <RightsWrapper>
                <WebsiteRights>Ascent Xmedia GmbH {new Date().getFullYear()} All rights reserved</WebsiteRights>
            </RightsWrapper>
                    <SocialMediaWrap>
                        {/* <SocialLogo to='/' onClick={toggleHome}>
                            Ascent Xmedia
                        </SocialLogo> */}
                        <SocialIcons>
                            <SocialIconLink href='//www.facebook.com/ascent-xmedia' target='_blank' aria-label='Facebook'>
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href='https://www.instagram.com/ascentxmedia/?hl=en' target='_blank' aria-label='Instagram'>
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href='/' target='_blank' aria-label='Getty'>
                                <FaLinkedinIn />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
