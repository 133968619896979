
// Homepage Slides -- Photo and text

export const SlideOne = {
    id: 'about',
    header: 'WHAT IS ASCENT XMEDIA?',
    description1: 'Ascent Xmedia is a stock photography service dedicated to photographers and filmmakers - and has been closely partnered with Getty Images for over 25 years.',
    description2: 'We work exclusively with Getty Images, and partner company iStock, while managing all aspects of post production for our global community of contributors, including file uploading, trimming, editing, color grading, file naming, photoshopping and keywording. We also attend to every related business need, such as sales reporting, usage negotiations, asset management, and payments.',
    description3: 'With Ascent Xmedia representation, you can focus on shooting and living adventures and let us handle the rest!',
    imgStart: true,
    img: require('../images/AXM-PK-29304.jpg').default,
    alt: 'mountain scenic',

}

export const SlideTwo = {
    id:'services',
    header: 'WHY UPLOAD YOUR WORK TO ASCENT XMEDIA?',
    description1:'We find that a lot of photographers and filmmakers have large libraries of great work on hard drives or computers, when they could in fact be generating a revenue stream through stock sales.',
    description2:'With a huge demand for online content in the marketplace, your JPEGs or video clips could be circulating the commercial & editorial world and bringing in a nice passive income, rather than be buried in aging archives.',
    description3:'Also - going forward - shooting new stock media allows you to create work in your own time and with total creative freedom.',
    imgStart: false,
    img: require('../images/night-light.jpg').default,
    alt:'base jumper leaps into sunglight'
}

export const SlideThree = {
    id: null,
    header: 'Build a Residual income stream',
    description1:'Your photos and video have the potential to be earning you a passive income. We specialize in maximizing the return you can earn from your media content.',
    description2:"We'll help you establish and grow a steady scalable source of passive income.",
    description3:'All you have to do is submit your media and we’ll make sure it enters the marketplace and gets sold. ',
    imgStart: true,
    img: require('../images/AXM-SS-0008.jpg').default,
    alt: 'placeholder image'
}

export const SlideFour = {
    id: null,
    header:'What can I earn?',
    description1:'Through our exclusive and preferential agreement with Getty Images, you are guaranteed the same or a higher royalty rate while working with us than you would be by uploading directly to Getty or iStock',
    description2:'So working through Ascent is easier, faster and more profitable than going at it alone. Our royalty rates are broken down in brackets, ensuring that you take home a bigger cut as you accumulate revenue.',
    description3:"Our royalty splits begin at 50/50, once you've earned $10,000 with us, we begin increasing your earnings as follows: $10,000 55/45, $40,000 60/40 and $100,000 65/35.",
    imgStart: false,
    img: require('../images/AXM-PKS-30902.jpg').default,
    alt:'placeholder image'
}

export const SlideFive = {
    id:'temp',
    header: 'WHERE WILL MY IMAGES GO?',
    description1:'Getty Images are the stock photography industry leaders, serving more than 1.5 million customers in over 200 countries. Their vast distribution network ensures your work is seen by the maximum number of potential customers globally.',
    description2:'Your images can be purchased for advertising, marketing, apps, websites, social media, TV and movie productions and for many other uses.',
    description3:'When a buyer chooses images, they purchase a license that allows them to download your files for commercial use - resulting in a sale for you, the contributor.',
    imgStart: true,
    img: require('../images/_MG_8698.jpg').default,
    alt:'another-placeholder' 
}

// Tutorial Videos
export const TutorialOne = {
    titleText:"Where to Begin?",
    contentText:"If you haven't submitted stock media before and aren't sure where to start, this video should provide you with some insight on where to begin:",
    videoUrl:"https://www.youtube.com/embed/rSspFfgOYOA",
    videoTitle:"Media Selection Tutorial",
    
}

export const TutorialTwo = {
    titleText:"how to Upload",
    contentText:"If you're a new contributor or returning after a hiatus we have a tutorial on how best to upload and organzie your media for editing:",
    videoUrl:"https://www.youtube.com/embed/Mp-RyQhRAaM",
    videoTitle:"How to Upload Tutorial",
    
}

export const TutorialThree = {
    titleText:"Preparing Model Releases",
    contentText:"Every image or video clip with a recognizeable person in it will need a model release filled out. This can be a confusing process as there are several essential fields to fill out, this video explains how to fill out your releases with the Easy Release App.",
    videoUrl:"https://www.youtube.com/embed/kCp8-s1D_9Q",
    videoTitle:"Releases Tutorial"
}

// Contributor Profile Slides

export const Contributor1 = {
    rowSlot: 1,
    photo: require('../images/Contributors/pks.png').default,
    alt:'PKS Photo',
    contribName: 'PKS Media',
    contribDescription: 'www.ascentxmedia.com'
}

export const Contributor2 = {
    rowSlot: 2,
    photo: require('../images/Contributors/Milo.png').default,
    alt:'Milo Zanecchia Photo',
    contribName: 'Milo Zanecchia',
    contribDescription: 'http://milozanecchia.com/'
}

export const Contributor3 = {
    rowSlot: 3,
    photo: require('../images/Contributors/bfc.png').default,
    alt:'bfc Logo',
    contribName: 'Black Forest Collective',
    contribDescription: 'https://bfc.film/'
}

export const Contributor4 = {
    rowSlot: 4,
    photo: require('../images/Contributors/wsm.png').default,
    alt:'WSM Photo',
    contribName: 'Wintersun Media',
    contribDescription: 'https://www.instagram.com/joshsmiht/'
}


export const Contributor5 = {
    rowSlot: 1,
    photo: require('../images/Contributors/Andrii.png').default,
    alt:'Andrii Profile',
    contribName: 'Andrii Lutsyk',
    contribDescription: 'https://www.andriilutsyk.com/',
}

export const Contributor6 = {
    rowSlot: 2,
    photo: require('../images/Contributors/alebeltrame.png').default,
    alt:'Alessandro Beltrame Photo',
    contribName: 'Alessandro Beltrame',
    contribDescription: 'http://www.agbvideo.com/',
}

export const Contributor7 = {
    rowSlot: 3,
    photo: require('../images/Contributors/cingalls.png').default,
    alt:'Cameron Ingalls Photo',
    contribName: 'Cameron Ingalls',
    contribDescription: 'https://www.cameroningalls.com/',
}

// export const Contributor8 = {
//     rowSlot: 4,
//     photo: require('../images/Contributors/Reuben.png').default,
//     alt:'Andrii Profile',
//     contribName: 'Christophe Angot',
//     contribDescription: 'www.ascentxmedia.com',
// }

export const Contributor9 = {
    rowSlot: 4,
    photo: require('../images/Contributors/Clement.png').default,
    alt:'Clement Hodgkinson Photo',
    contribName: 'Clement Hodgkinson',
    contribDescription: 'https://www.clementhodgkinson.com/',
}

// export const Contributor10 = {
//     rowSlot: 1,
//     photo: require('../images/Contributors/Reuben.png').default,
//     alt:'Andrii Profile',
//     contribName: 'East Road',
//     contribDescription: 'www.ascentxmedia.com',
// }

export const Contributor11 = {
    rowSlot: 1,
    photo: require('../images/Contributors/FabioBalassi.png').default,
    alt:'Fabio Balassi Photo',
    contribName: 'Fabio Balassi',
    contribDescription: 'https://www.instagram.com/_balassi_/?hl=en',
}

export const Contributor12 = {
    rowSlot: 2,
    photo: require('../images/Contributors/Francoise.png').default,
    alt:'Françoise Gervais Photo',
    contribName: 'Françoise Gervais',
    contribDescription: 'https://fgervaisgallery.com/',
}

// export const Contributor13 = {
//     rowSlot: 1,
//     photo: require('../images/Contributors/Reuben.png').default,
//     alt:'Andrii Profile',
//     contribName: 'Giorgio Bernardi',
//     contribDescription: 'www.ascentxmedia.com',
// }

export const Contributor14 = {
    rowSlot: 3,
    photo: require('../images/Contributors/GregJacobs.png').default,
    alt:'Greg Jacobs Photo',
    contribName: 'Greg Jacobs',
    contribDescription: 'https://www.capturesharerepeat.com/',
}

export const Contributor15 = {
    rowSlot: 4,
    photo: require('../images/Contributors/gabriel.png').default,
    alt:'Gabriel Kocher Photo',
    contribName: 'Gabriel Kocher',
    contribDescription: 'https://www.instagram.com/gab707_fpv/?hl=en',
}

// export const Contributor16 = {
//     rowSlot: 4,
//     photo: require('../images/Contributors/Reuben.png').default,
//     alt:'Andrii Profile',
//     contribName: 'Graham Pearsall',
//     contribDescription: 'www.ascentxmedia.com',
// }

export const Contributor17 = {
    rowSlot: 1,
    photo: require('../images/Contributors/jharris.png').default,
    alt:'Jon Harris Profile',
    contribName: 'Jon Harris',
    contribDescription: 'https://www.instagram.com/jonharrris/?hl=en',
}

export const Contributor18 = {
    rowSlot: 2,
    photo: require('../images/Contributors/kledeboer.png').default,
    alt:'Kyle Ledeboer Photo',
    contribName: 'Kyle Ledeboer',
    contribDescription: 'https://www.kplstudios.com/',
}

export const Contributor19 = {
    rowSlot: 3,
    photo: require('../images/Contributors/mikecrane.png').default,
    alt:'Mike Crane Profile',
    contribName: 'Mike Crane',
    contribDescription: 'https://www.whistlerphotovideo.com/',
}


export const Contributor20 = {
    rowSlot: 4,
    photo: require('../images/Contributors/ollie.png').default,
    alt:'Oliver Furrer Photo',
    contribName: 'Oliver Furrer',
    contribDescription: 'http://www.oliverfurrer.com/',
}

export const Contributor21 = {
    rowSlot: 1,
    photo: require('../images/Contributors/rivaphoto.png').default,
    alt:'Riva Photo logo',
    contribName: 'Riva Pictures',
    contribDescription: 'https://rivapictures.com/work',
}


export const Contributor22 = {
    rowSlot: 2,
    photo: require('../images/Contributors/Reuben.png').default,
    alt:'Reuben Krabber Photo',
    contribName: 'Reuben Krabbe',
    contribDescription: 'https://reubenkrabbe.com/',

}

export const Contributor23 = {
    rowSlot: 3,
    photo: require('../images/Contributors/simonhird.png').default,
    alt:'Simon Hird Photo',
    contribName: 'Simon Hird',
    contribDescription: 'https://simonhird.com/',
}

export const Contributor24 = {
    rowSlot: 4,
    photo: require('../images/Contributors/silakos.png').default,
    alt:'Simon Lakos Photo',
    contribName: 'Simon Lakos',
    contribDescription: 'http://simonlakos.com/',
}

export const Contributor25 = {
    rowSlot: 1,
    photo: require('../images/Contributors/toddeasterbrook.png').default,
    alt:'Todd Easterbrook Photo',
    contribName: 'Todd Easterbrook',
    contribDescription: 'https://toddeasterbrook.com/',
}

export const Contributor26 = {
    rowSlot: 2,
    photo: require('../images/Contributors/tkp.png').default,
    alt:'Taja KP',
    contribName: 'Taja Košir Popovič',
    contribDescription: 'https://tajakp.com/',
}

export const Contributor27 = {
    rowSlot: 2,
    photo: require('../images/Contributors/wildeurope.png').default,
    alt:'Wild Europe Logo',
    contribName: 'Wild Europe',
    contribDescription: 'https://www.wild-europe.org/',
}




