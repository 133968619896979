import React from 'react'
import {AdWrapper,
        TitleWrapper,
        TitleWrapper2,
        Title,
        HeaderWrapper,
        Headerimg,
        SectionWrapper,
        SectionContent1a,
        SectionContent1b,
        ContentBox,
        SectionContent4,
        SectionContent5,
        SectionContent6,
        SectionTitle,
        SectionTitleP2,
        P2Title,
        P2Text,
        ContactSection,
        ContactText,
        SectionImg } from './AdSplashElements'
import {FooterLink} from '../Footer/FooterElements'
import HeaderImg from '../../images/AXM-PM-A11-G01.jpg'
import TextImg from '../../images/photographer-web.jpg'

const AdSplash = () => {
  return (
    <AdWrapper>
        <TitleWrapper>
            <Title>Start your stock media collection with a cash advance</Title>
        </TitleWrapper>
        <HeaderWrapper>
            <Headerimg src={HeaderImg} />
        </HeaderWrapper>
        <TitleWrapper2>
            <SectionTitle>Join Our Community of Content Creators.</SectionTitle>
        </TitleWrapper2>
        <SectionWrapper>
            <ContentBox>
                <SectionContent1a>Earn up to a $1000 cash advance while working with us to establish your stock media library. The advance program allows you to begin earning immediately while also building passive income stream for future payouts.</SectionContent1a>
            </ContentBox>
            <ContentBox>
                <SectionContent1b>We'll help you learn what makes a successful stock photo/video while also expanding your reach to markets and clients you would not normally access.</SectionContent1b>
            </ContentBox>
            <SectionImg src={TextImg} />
            <SectionTitleP2>Is your content eligible for a cash advance?</SectionTitleP2>
            <SectionContent4>
                <P2Title>Editing Standards.</P2Title>
                <P2Text>In order for your media to be considered for a cash advance, we first need to ensure that resolutions are high enough. Images and clips must pass through a technical and creative editing process before payment is issued.</P2Text>
            </SectionContent4>
            <SectionContent5>
                <P2Title>Commercial Viability.</P2Title>
                <P2Text>You might be staring at a collection of existing media or a recent shoot and wondering where to begin and which of your shots are most commercially viable. If you send us a link to your instagram or portfolio we can help you identify where to start.</P2Text>
            </SectionContent5>
            <SectionContent6>
                <P2Title>Model Releases</P2Title>
                <P2Text>The first and most challenging pre-requisite is that we require all recognizeable people in your media to have an accompanying model release (also property in some cases). If this is unfamilliar to you dont worry! We'll help you get started.</P2Text>
            </SectionContent6>
        </SectionWrapper>
        <ContactSection>
            <ContactText>Interested? <FooterLink to='/contact'>Contact Us</FooterLink> to get started.</ContactText>
        </ContactSection>
    </AdWrapper>
  )
}

export default AdSplash