import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../theme'
import Navbar from '../components/Navbar';
import Earnings from '../components/Earnings';
import Footer from '../components/Footer';
import SidebarTwo from '../components/SidebarTwo';

const Contact = ({isOpen, toggleSidebar}) => {

    return (
        <ThemeProvider theme={theme}>
            <SidebarTwo isOpen={isOpen} toggle={toggleSidebar} />
            <Navbar toggle={toggleSidebar} />
            <Earnings />
            <Footer />
        </ThemeProvider>
    )
}

export default Contact
