import React from 'react'
import ContributorContent from '../ContributorContent'
import { Contributor1, 
         Contributor2,
         Contributor3,
         Contributor4,
         Contributor5,
         Contributor6,
         Contributor7, 
         Contributor9,
         Contributor11, 
         Contributor12, 
         Contributor14,
         Contributor15,
         Contributor17,
         Contributor18,
         Contributor19,
         Contributor20,
         Contributor21,
         Contributor22,
         Contributor23,
         Contributor24,
         Contributor25,
         Contributor26,
         Contributor27,  } from '../../components/Data'
import { GridWrapper,
         TitleWrapper, 
         GridTitle,
         Grid,
         } from './ContributorGridElements'

const ContributorGrid = () => {
    return (
        <GridWrapper>
            <TitleWrapper>
                <GridTitle>Our Contributors</GridTitle>
            </TitleWrapper>
            <Grid>
                <ContributorContent {...Contributor1} />
                <ContributorContent {...Contributor2} />
                <ContributorContent {...Contributor3} />
                <ContributorContent {...Contributor4} />
                <ContributorContent {...Contributor5} />
                <ContributorContent {...Contributor6} />
                <ContributorContent {...Contributor7} />
                <ContributorContent {...Contributor9} />
                <ContributorContent {...Contributor11} />
                <ContributorContent {...Contributor12} />
                <ContributorContent {...Contributor14} />
                <ContributorContent {...Contributor15} />
                <ContributorContent {...Contributor17} />
                <ContributorContent {...Contributor18} />
                <ContributorContent {...Contributor19} />
                <ContributorContent {...Contributor20} />
                <ContributorContent {...Contributor21} />
                <ContributorContent {...Contributor22} />
                <ContributorContent {...Contributor23} />
                <ContributorContent {...Contributor24} />
                <ContributorContent {...Contributor25} />
                <ContributorContent {...Contributor26} />
                <ContributorContent {...Contributor27} />
                
            </Grid>
        </GridWrapper> 
    )
}
 
export default ContributorGrid
