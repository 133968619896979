import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../theme'
import Navbar from '../components/Navbar';
import AdSplash from '../components/AdSplash';
import Footer from '../components/Footer';
import SidebarTwo from '../components/SidebarTwo';

const Advertisement = ({isOpen, toggleSidebar}) => {

    return (
        <ThemeProvider theme={theme}>
            <SidebarTwo isOpen={isOpen} toggle={toggleSidebar} />
            <Navbar toggle={toggleSidebar} />
            <AdSplash />
            <Footer />
        </ThemeProvider>
    )
}

export default Advertisement