import React from 'react';
import { IconContext } from 'react-icons/lib';
import { FaBars } from "react-icons/fa";
import { Nav,
         NavbarContainer,
         NavLogo, 
         Logo,
         MobileIcon,
         NavMenu, 
         NavItem, 
         NavLinks,
         NavBtn,
         NavBtnLink 
        } from './NavbarElements';
import Logoimg from '../../images/Logo/axm-logo.png'

const Navbar = ({ toggle, scrollNav, toggleHome }) => {
    
    return (
        <>
        <IconContext.Provider value={{ color: '#fff'}}>
        <Nav scrollNav={scrollNav}>
                <NavLogo to='/' onClick={toggleHome} scrollnav={scrollNav}>
                    <Logo src={Logoimg} alt='Logo'/>
                </NavLogo >
            <NavbarContainer>
                <MobileIcon onClick={toggle}>
                    <FaBars color='#000' />
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to="/#about"
                                  smooth={true} 
                                  duration={500} 
                                  spy={true} 
                                  exact='true' 
                                  scrollNav={scrollNav}
                                  >
                                    About
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="/#services"
                                  smooth={true} 
                                  duration={500} 
                                  spy={true} 
                                  exact='true' 
                                  scrollNav={scrollNav}
                                  >
                                      How it Works
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="/#FAQ"
                                  smooth={true} 
                                  duration={500} 
                                  spy={true} 
                                  exact='true' 
                                  scrollNav={scrollNav}
                                  >
                                      FAQ
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="/contributor"
                                  smooth={true} 
                                  duration={500} 
                                  spy={true} 
                                  exact='true' 
                                  scrollNav={scrollNav}
                                  >
                                      Contributors
                        </NavLinks>
                    </NavItem>
                </NavMenu>
                {/* <NavBtn>
                    <NavBtnLink to="/signin" scrollNav={scrollNav} >Sign In</NavBtnLink>
                </NavBtn> */}
            </NavbarContainer>
        </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar
