import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../theme';
import Navbar from '../components/Navbar'
import SidebarTwo from '../components/SidebarTwo'
import Footer from '../components/Footer';
import SubmissionDetails from '../components/SubmissionDetails';

const UploadInfo = ({isOpen, toggleSidebar}) => {
    return (
        <ThemeProvider theme={theme} >
            <Navbar toggle={toggleSidebar} />
            <SidebarTwo isOpen={isOpen} toggle={toggleSidebar} />
            <SubmissionDetails />
            <Footer />
        </ThemeProvider>
    )
}

export default UploadInfo
