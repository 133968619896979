import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../theme';
import ContributorGrid from '../components/ContributorGrid'
import Navbar from '../components/Navbar'
import SidebarTwo from '../components/SidebarTwo'
import Footer from '../components/Footer';

const ContribPage = ({isOpen, toggleSidebar}) => {

    return (
        <ThemeProvider theme={theme}>
            <Navbar toggle={toggleSidebar} />
            <SidebarTwo isOpen={isOpen} toggle={toggleSidebar}/>
            <ContributorGrid />
            <Footer />
        </ThemeProvider>
    )
}

export default ContribPage
