import React from 'react'
import { FooterLink } from '../Footer/FooterElements';
import { FAQContainer,
         TextWrapper,
         Header,
         Section,
         Title,
         ParagraphText } from './FAQElements';

const FAQ = () => {
    return (
        <FAQContainer id='FAQ'>
            <TextWrapper>
                <Header>FAQ</Header>
                <Section>
                    <Title>Can I still use my images and video even if on Getty?</Title>
                    <ParagraphText>Absolutely! Your content will be licensed to customers around the world on your behalf while you hold the copyright.</ParagraphText>
                </Section>
                <Section>
                    <Title>Can I upload past client work?</Title>
                    <ParagraphText>Yes, if the client approves. We inform Getty Images that specific client has temporary rights to the images before uploading.</ParagraphText>
                </Section>
                <Section>
                    <Title>What can I shoot?</Title>
                    <ParagraphText>Anything! That is the beauty. Total creative freedom. But the more universal the appeal, and the more conceptual the content, the more likely sales will soon materialize</ParagraphText>
                </Section>
                <Section>
                    <Title>How do I submit content to Ascent Xmedia?</Title>
                    <ParagraphText>Google Drive, DropBox, WeTransfer, Hard Drive</ParagraphText>
                </Section>
                <Section>
                    <Title>For more information please contact us <u><FooterLink to='/contact'>here</FooterLink></u>!</Title>
                </Section>
                {/* <Section>
                    <Title>Here are some visual tutorials outlining the submission process:</Title>
                    <FooterLink to='/tutorials'>Video Tutorials</FooterLink>
                </Section> */}
            </TextWrapper>
        </FAQContainer>
       
    )
}

export default FAQ
