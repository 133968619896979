import styled from "styled-components";

export const ElementWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding-top: 4rem;
    padding-bottom: 4rem;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        width: 50%;
    }
`;

export const Title = styled.h2`
    letter-spacing: 0.5rem;
    font-size: 1.5rem;
`;

export const ContentWrapper = styled.div`
    width: 80%;
`;

export const ContentSection = styled.div`
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
`;
         
export const ContentText = styled.span`
    font-family: 'Inter', sans-serif;
    padding-top: 1rem;
`;

export const Minititle = styled.span`
    font-family: 'Inter', sans-serif;
    font-weight: bold;
`;
         
export const SubmissionFAQContainer =styled.div`
    border-top: 1px solid rgba(0,0,0,0.2);
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
`;
         
export const BulletTitle = styled.text`
    letter-spacing: 0.1rem;
    padding-left: 0.5rem;
`
         
export const BulletContent =styled.text`
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 2rem;
`