import React from 'react';
import { ContactWrapper,
    ContactContent,
    Column1,
    Column2,
    Title,
    Subtext } from './ContactElements';

const ContactMain = () => {
return (
   <ContactWrapper>
       <ContactContent>
           <Column1>
               <Title>Contact Us With Inquiries</Title>
           </Column1>
           <Column2>
               <Subtext>info@ascentxmedia.com</Subtext>
               <Subtext>+41 91 922 52 31</Subtext>
               <Subtext>Via Lavizzari 4, 6900 Lugano </Subtext>
           </Column2>
       </ContactContent>
   </ContactWrapper>
)
}

export default ContactMain