import styled from "styled-components";

export const AdWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    justify-content: center;
`;


export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.3rem;
`

export const TitleWrapper2 = styled.div`
    display: flex; 
    justify-content: center;
    margin-top: 8rem;
    padding-top: 6rem;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        padding-top: 0rem;
        margin-top: 7rem;
        padding-bottom: 0.7rem;
    }
`

export const Title = styled.h1`
    font-size: 2.5rem;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
     font-size: 1.5rem;
     padding: 1rem;
     text-align: center;
 }
`
export const HeaderWrapper = styled.div`
    display: flex;
    max-height: 400px;
    justify-content: center;
    align-content: center;
`;

export const Headerimg = styled.img`
    object-fit: cover;
    object-position: 0 40%;
    height: 500px;
    width: 100%;
`;

//Grid Container

export const SectionWrapper = styled.div`
 display: grid;
 grid-template-columns: repeat(12, 1fr);
 grid-auto-rows: minmax(300px, 1fr) auto minmax(300px, 1fr) auto;
 grid-gap: 1rem;
 text-align: justify;
 align-items: center;
 max-width: 80%;
 margin-left: auto;
 margin-right: auto;

 @media screen and (max-width: ${({ theme }) => theme.tablet}) {
     display: block;    
 }

`

//Row 1

export const SectionTitle = styled.h2`
    font-size: 2rem;
    padding-left: 1rem;
    grid-row: 1;
    grid-column: span 4;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
    font-size: 1.5rem;
    padding-left: none;
    padding-top: 1rem;
    text-align: center;   
 }
`
export const ContentBox = styled.div`
    grid-row: 1;
    grid-column: span 6;
    padding-left: 1rem;
    height: 10%;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
`;

export const SectionContent1a = styled.span`
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
` 
export const SectionContent1b = styled.span`
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
` 
// Row 2

export const SectionTitleP2 = styled.h2`
    grid-column: span 12;
    grid-row: 3;
    font-size: 2rem;
    text-align: center;
    justify-self: center;

    @media screen and (max-width: ${({ theme }) => theme.tablet}) {
        font-size: 1.5rem;
        padding-top: 1rem;    
 }
`

export const SectionImg = styled.img`
    grid-row: 2;
    grid-column: span 12;
    height: 500px;
    object-fit: cover;
    object-position: 30% 85%;
    margin-left: -10vw;
    margin-right: -10vw;
    width: 100vw;
    max-width: 140%;
`;

//Row 3

export const SectionContent4 = styled.div`
    grid-row: 4;
    grid-column: span 4;
    padding-bottom: 1rem;
    text-align: center;
    outline: 1px solid black;
    align-self: start;
    margin-top: 3rem;
    padding: 1rem;
    height: 75%;
`

export const SectionContent5 = styled.div`
    grid-row: 4;
    grid-column: span 4;
    padding: 1rem;
    padding-bottom: 1rem;
    outline: 1px solid black;
    text-align: center;
    font-size: 1rem; 
    align-self: start;
    margin-top: 3rem;
    height: 75%;
`

export const SectionContent6 = styled.p`
    grid-row: 4;
    grid-column: span 4;
    outline: 1px solid black;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1rem;
    align-self: start;
    margin-top: 3rem;
    padding: 1rem;
    height: 75%;
`

export const P2Title = styled.p`
    font-size: 1.2rem;
    align-self: start;
`

export const P2Text = styled.a`
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    text-align: center;
`

export const ContactSection =styled.div`
   display: flex;
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   justify-content: center;
   text-align: center;
   margin-top: 3rem;
   padding-bottom: 3rem;
   border-bottom: 1px solid black;
`
export const ContactText = styled.a`
    font-family: 'Inter', sans-serif;
`


